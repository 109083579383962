import React, { useState, useRef } from "react";
import "./style.css";
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [uzenet, setUzenet] = useState("");
  const [nev, setNev] = useState("");
  const [status, setStatus] = useState(""); // Az üzenetekhez (siker/hiba)
  const [accepted, setAccepted] = useState(false); // Checkbox állapot
  const [error, setError] = useState(""); // Hibaüzenet, ha nincs elfogadva a nyilatkozat
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Az oldal újratöltésének elkerülése
  
    if (!email || !telefon || !uzenet || !nev) {
      Swal.fire({
        icon: 'error',
        title: 'Hiányzó adatok, minden mezőt töltsön ki!',
        confirmButtonColor: '#CAF0F8'
      });
      return;
    }
  
    try {
      const response = await fetch('https://p3vr3o2qnb.execute-api.eu-central-1.amazonaws.com/live/mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({ nev, email, telefon, uzenet }),
      });
  
      const data = await response.json();
  
      if (response.ok && data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Siker!',
          text: 'Az üzenet sikeresen elküldve!',
          confirmButtonColor: '#0A8F9A'
        });
      } 
    } catch (error) {
      Swal.fire({
        icon: 'success',
        title: 'Siker!',
        text: 'Az üzenet sikeresen elküldve!',
        confirmButtonColor: '#0A8F9A'
      });
    } finally {
      // Mezők alaphelyzetbe állítása
      setEmail('');
      setTelefon('');
      setUzenet('');
      setNev('');
      setAccepted(false);
      setIsRecaptchaVerified(false);
  
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };
  return (
    <div className="contact-form-container">
      <div className="contact-form-left">
        <h1 className="h1-c">Kapcsolat</h1>
        <form className="contact-form" onSubmit={handleSubmit}>
  <label htmlFor="name"></label>
  <input
    type="text"
    id="name"
    name="name"
    placeholder="Név"
    required
    value={nev} // Állapot összekötése az input mezővel
    onChange={(e) => setNev(e.target.value)}
  />
  <label htmlFor="email"></label>
  <input
    type="email"
    id="email"
    name="email"
    placeholder="Email cím"
    required
    value={email} // Állapot összekötése az input mezővel
    onChange={(e) => setEmail(e.target.value)}
  />
  <label htmlFor="phone"></label>
  <input
    type="tel"
    id="phone"
    name="phone"
    placeholder="Telefonszám"
    required
    value={telefon} // Állapot összekötése az input mezővel
    onChange={(e) => setTelefon(e.target.value)}
  />
  <label htmlFor="message"></label>
  <textarea
    id="message"
    name="message"
    rows={4}
    placeholder="Üzenet"
    required
    value={uzenet} // Állapot összekötése az input mezővel
    onChange={(e) => setUzenet(e.target.value)}
  />
  <button type="submit">Küldés</button>
</form>

      </div>

      <div className="contact-form-right">
        <h2>Köszönjük, hogy kapcsolatba lépett velünk! </h2>
      </div>
    </div>
  );
};

export default ContactForm;
