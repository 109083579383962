import React, { useState, useEffect, useRef } from "react";
import "./carousel.css";

interface CarouselItem {
  id: number;
  src: string;
}

const carouselItems: CarouselItem[] = [
  { id: 2, src: "https://www.lh.just-ready.com/" },
  { id: 1, src: "https://www.fandd.just-ready.com/" },
  { id: 3, src: "https://lehet.eu/" },
  { id: 4, src: "https://www.j.just-ready.com/" },
];

const Carousel: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [visibleItems, setVisibleItems] = useState<CarouselItem[]>([]);
  const [iframeActive, setIframeActive] = useState<boolean>(false);
  const touchStartX = useRef<number>(0);
  const touchEndX = useRef<number>(0);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const moveCarousel = (direction: "next" | "prev") => {
    setIframeActive(false);
    setSelectedIndex((prevIndex) => {
      if (direction === "next") {
        return prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1;
      } else {
        return prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1;
      }
    });
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
    setIsScrolling(false);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
    if (Math.abs(touchStartX.current - touchEndX.current) > 10) {
      setIsScrolling(true);
    }
  };

  const handleTouchEnd = () => {
    if (isScrolling) {
      const direction =
        touchStartX.current - touchEndX.current > 0 ? "next" : "prev";
      moveCarousel(direction);
    } else {
      setIframeActive(true);
    }
  };

  useEffect(() => {
    const updateVisibleItems = () => {
      const prevIndex =
        (selectedIndex - 1 + carouselItems.length) % carouselItems.length;
      const nextIndex = (selectedIndex + 1) % carouselItems.length;
      setVisibleItems([
        carouselItems[prevIndex],
        carouselItems[selectedIndex],
        carouselItems[nextIndex],
      ]);
    };
    updateVisibleItems();
  }, [selectedIndex]);

  return (
    <section className="carousel-section">
      <h1 className="carousel-title">Munkáink</h1>
      <div
        className="carousel-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="carousel">
          {visibleItems.map((item, index) => {
            const className =
              index === 0 ? "prev" : index === 1 ? "selected" : "next";
            return (
              <div
                key={item.id}
                className={`iframe-container ${className} ${
                  iframeActive && index === 1 ? "active" : ""
                }`}
                onClick={() => index === 1 && setIframeActive(true)}
              >
                <iframe
                  src={item.src}
                  title={`Carousel item ${item.id}`}
                  width="400px"
                  height="400px"
                  frameBorder="0"
                  className={`no-scrollbar ${
                    iframeActive && index === 1 ? "scrollable" : ""
                  }`}
                  style={{
                    pointerEvents:
                      iframeActive && index === 1 ? "auto" : "none",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="carousel-controls">
    <button
      className="carousel-arrow carousel-arrow-left"
      onClick={() => moveCarousel("prev")}
    >
      &#9664;
    </button>
    <div className="carousel-dots">
      {carouselItems.map((_, index) => (
        <span
          key={index}
          className={`dot ${selectedIndex === index ? "active" : ""}`}
          onClick={() => setSelectedIndex(index)}
        ></span>
      ))}
    </div>
    <button
      className="carousel-arrow carousel-arrow-right"
      onClick={() => moveCarousel("next")}
    >
      &#9654;
    </button>
  </div>
    </section>
  );
};

export default Carousel;
