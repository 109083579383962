import React from 'react';
import './main.css';

const App = () => {
  return (
    <section className='logo-s'>
    <div className="main">
      <img src="logo.png" alt="Card image" className="img" />
    </div>
    </section>
  );
}

export default App;