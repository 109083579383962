import React from 'react'
import "./wave.css"

const Wave = () => {

    const pdf = () => {
        window.open('./Adatkezelési Nyilatkozat.pdf'); 
      };


  return (
   <section id='section-wave'>
     <div className='text-center p-3' style={{  letterSpacing: "2px", marginTop: "50px" }}>
      <div className='wavep2'  style={{ cursor: 'pointer' }}> 
      <p onClick={pdf} >Adatkezelési Nyilatkozat</p></div>
    </div>
    <div className='wave wave1'></div>
    <div className='wave wave2' ></div>
    <div className='wave wave3' ></div>
    <div className='wave wave4' ></div>
   </section>
  )
}

export default Wave