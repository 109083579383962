import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './Main/Main';
import Carousel from './Carousel/Carousel';
import Contact from './Contact/Contact';
import FormComponent from './Contact-variations/Contact-variations';
import Footer from './Footer/Footer';
import Wave from './Wave/Wave';

function App() {
  return (
    <div className="App">
     <Main/> 
     <Carousel /> 
     <FormComponent />
     <Wave/>
    </div>
  );
}

export default App;
